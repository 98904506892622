import { useState, useEffect, useRef } from "react";
import { Box, Flex, css, Icon } from "@storyofams/react-ui";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { defineMessages, FormattedMessage } from "react-intl";

import {
  Heading,
  Text,
  ScaleOnHover,
  HoverArea,
  FixedRatio,
  Button,
} from "../../../components";
import { Star } from "../Icons";

const messages = defineMessages({
  readMore: "Lees meer",
});

const getType = (src: string) => {
  const dimensions = src?.match(/\/(\d*)x(\d*)\//);

  const w = dimensions?.[1];
  const h = dimensions?.[2];

  return w && h && parseInt(w) / parseInt(h) < 1 ? "portrait" : "landscape";
};

export const BlogCard = ({
  firstPublishedAt,
  sort_by_date,
  sortByDate,
  publishedAt,
  published_at,
  slug,
  full_slug,
  content,
  breakpoint = "md",
  isFeatured,
  displayDate,
  isShortCard,
  isRecipePage,
  ...props
}: any) => {
  const [type, setType] = useState(getType(content?.thumbnail?.filename));
  const [text, setText] = useState(content?.summary?.text || content?.summary);
  const contentRef = useRef<HTMLDivElement>();
  const { query } = useRouter();
  useEffect(() => {
    const currentType = getType(content?.thumbnail?.filename);

    if (type !== currentType) {
      setType(currentType);
    }
  }, [content?.thumbnail]);
  // const foundedIngredient =
  //   content.ingredients &&
  //   query?.q &&
  //   content.ingredients
  //     ?.split(",")
  //     .find((item) => item.toLowerCase().includes(query?.q));
  const isShort = contentRef?.current?.getBoundingClientRect().height > 23;
  useEffect(() => {
    if (isFeatured) return;
    if (isShort) {
      setText((text) => {
        return text.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 120) + "...";
      });
    }
    if (text && text.length > 180)
      setText((text) => {
        return text.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 180) + "...";
      });
  }, [text]);
  return (
    <Link
      prefetch={false}
      href={
        full_slug
          ? `/${full_slug}`
          : `/${
              content?.component === "blog"
                ? "blog"
                : content?.component === "plant"
                ? "plant"
                : "recipes"
            }/${slug || content?.slug}`
      }
      as={
        full_slug
          ? `/${full_slug}`
          : `/${
              content?.component === "blog"
                ? "blog"
                : content?.component === "plant"
                ? "plant"
                : "recipes"
            }/${slug || content?.slug}`
      }
      passHref
    >
      <Box
        as="a"
        width="100%"
        {...props}
        css={{ "&:hover": { cursor: "pointer" } }}
      >
        <HoverArea
          css={css({
            border: "1px",
            borderColor: ["grey200", "grey200"],
          })}
          borderRadius={"md"}
        >
          <Flex
            borderRadius="inherit"
            flexDirection={isFeatured ? "row" : { [breakpoint]: "column" }}
            py={{ all: isFeatured ? 0 : 1.5, [breakpoint]: 3 }}
            alignItems={isFeatured && ["unset", "unset", "center"]}
            px={isFeatured && { [breakpoint]: 3 }}
          >
            {isFeatured ? (
              <FixedRatio
                height={[content?.difficulty ? "60px" : "85px", "100%"]}
                ratio={[
                  [1, 1],
                  isFeatured
                    ? [1, 1]
                    : type === "landscape"
                    ? [336, 218]
                    : [336, 384],
                ]}
                position="relative"
                overflow="hidden"
                ml={[1.5, 0]}
                mt={[1.5, 0]}
                maxHeight="400px"
                borderRadius="md"
                borderBottomLeftRadius={"md"}
                borderBottomRightRadius={"md"}
                minWidth={67}
                flex={["none", 1]}
                mb={[1, 0]}
              >
                <ScaleOnHover>
                  <Image
                    priority={true}
                    alt={content?.thumbnail?.alt || ""}
                    src={
                      (content?.thumbnail?.filename &&
                        content?.thumbnail?.filename + "/m/550x400") ||
                      content?.recipe_thumbnail?.filename ||
                      content?.image
                    }
                    objectFit="cover"
                    layout="fill"
                  />
                </ScaleOnHover>
              </FixedRatio>
            ) : (
              <Box px={{ [breakpoint]: 3 }}>
                <FixedRatio
                  ratio={[
                    [1, 1],
                    isFeatured
                      ? [1, 1]
                      : type === "landscape"
                      ? [336, 218]
                      : [336, 384],
                  ]}
                  height={[content?.difficulty ? "60px" : "85px", "100%"]}
                  position="relative"
                  overflow="hidden"
                  borderRadius="md"
                  borderBottomLeftRadius={"md"}
                  borderBottomRightRadius={"md"}
                  minWidth={67}
                  ml={[1.5, 1.5, 0]}
                  flex={isFeatured && 1}
                >
                  <ScaleOnHover>
                    {(content?.image || content?.thumbnail?.filename) && (
                      <Image
                        alt={content?.thumbnail?.alt || ""}
                        src={
                          content?.recipe_thumbnail?.filename ||
                          (content?.thumbnail?.filename &&
                            content?.thumbnail?.filename + "/m/340x220") ||
                          (content?.image?.includes("storyblok")
                            ? content?.image + "/m/340x220"
                            : content?.image)
                        }
                        layout="fill"
                        objectFit="cover"
                        loading="lazy"
                      />
                    )}
                  </ScaleOnHover>
                </FixedRatio>
              </Box>
            )}
            <Box
              ml={{ all: 1.5, [breakpoint]: isFeatured ? 5 : 0 }}
              mt={isFeatured ? [2, 0] : { all: 0.5, [breakpoint]: 28 }}
              textAlign="left"
              py={isFeatured && [0, 3, 0]}
              pr={["12px", 0]}
              width={"100%"}
              flex={isFeatured && 1}
            >
              <Flex
                justifyContent={"space-between"}
                px={{ [breakpoint]: 3 }}
                height="14px"
              >
                <Heading
                  variant={isFeatured ? ["h7", "h6"] : "h7"}
                  as="h5"
                  color="grey600"
                  fontWeight={isFeatured && ["400", "medium"]}
                  css={css({ textTransform: "uppercase" })}
                >
                  {full_slug?.includes("blog") || content?.component === "blog"
                    ? "blog"
                    : full_slug?.includes("plant")
                    ? "plant"
                    : "recepten"}
                </Heading>
                {!!content?.rating && (
                  <Flex>
                    <Text
                      fontWeight={700}
                      mr="5px"
                      pt="2px"
                      lineHeight={"12px"}
                    >
                      {content.rating}
                    </Text>
                    <Icon color={"oldPink"} icon={Star} lineHeight={"12px"} />
                  </Flex>
                )}
              </Flex>

              <Heading
                px={{ [breakpoint]: 3 }}
                variant={isFeatured ? ["h5", "h3"] : "h5"}
                as="h4"
                overflow="hidden"
                ref={contentRef}
                mt={isFeatured ? ["4px", 1] : ["4px", 0.5]}
                fontWeight={isFeatured && [600, "medium"]}
                css={
                  !isFeatured
                    ? css({ textTransform: "uppercase" })
                    : [css({ textTransform: "uppercase" }), "none"]
                }
              >
                {content?.title?.text || content?.title}
              </Heading>
              {content?.difficulty && (
                <Box
                  mt={2}
                  height="44px"
                  width="100%"
                  px={{ [breakpoint]: 3 }}
                  bg="#FCF9F7"
                  fontWeight={600}
                  fontSize="13px"
                  lineHeight={"16px"}
                  borderY="1px solid grey200"
                  alignItems="center"
                  whiteSpace={"nowrap"}
                  display={["none", "none", "flex"]}
                >
                  <Box
                    height="24px"
                    pt={"2px"}
                    fontSize="13px"
                    fontWeight={500}
                    mr={2}
                  >
                    {content?.difficulty.text.toUpperCase()}
                  </Box>
                  <Box
                    height="24px"
                    pt={"2px"}
                    fontSize="13px"
                    fontWeight={500}
                    mr={2}
                  >
                    {content?.total_time} MIN.
                  </Box>
                  <Box
                    height="24px"
                    pt={"2px"}
                    overflow="hidden"
                    css={{ textOverflow: "clip" }}
                    fontSize="13px"
                    fontWeight={500}
                  >
                    {`${
                      content.ingredients
                        ? content.ingredients.split(",")?.length
                        : content?.recipes_ingredients?.length
                    }
                     INGREDIENTEN`}
                  </Box>
                </Box>
              )}
              {/* {foundedIngredient && (
                <Text color="grey600" px={{ [breakpoint]: 3 }}>
                  includes {foundedIngredient}
                </Text>
              )} */}
              <Text
                px={{ [breakpoint]: 3 }}
                display={{
                  all: "none !important",
                  [breakpoint]: "block !important",
                }}
                color="grey600"
                maxHeight={isShort ? "73px" : "102px"}
                overflowY="hidden"
                mt={isFeatured ? [1, 3] : isShort ? "24px" : 2}
              >
                {text}
              </Text>
              {/* {displayDate &&
                !!(
                  sort_by_date ||
                  sortByDate ||
                  firstPublishedAt ||
                  publishedAt ||
                  published_at ||
                  content?.created_at
                ) && (
                  <Text
                    mt={["4px", isFeatured ? 4 : 3]}
                    as="span"
                    display="block"
                    css={{ textTransform: "uppercase" }}
                    color="grey600"
                    fontSize={1.5}
                    mb={isFeatured && "12px"}
                    fontWeight="medium"
                    px={{ [breakpoint]: 3 }}
                  >
                    <FormattedDate
                      day="numeric"
                      month="long"
                      value={
                        sort_by_date ||
                        sortByDate ||
                        firstPublishedAt ||
                        publishedAt ||
                        published_at ||
                        content.created_at
                      }
                      {...(!isSameYear(
                        new Date(),
                        new Date(
                          sort_by_date ||
                            sortByDate ||
                            firstPublishedAt ||
                            publishedAt ||
                            published_at ||
                            content.created_at
                        )
                      )
                        ? { year: "numeric" }
                        : {})}
                    />
                  </Text>
                )} */}

              {isFeatured && (
                <Button
                  mx={{ [breakpoint]: 3 }}
                  mt={4}
                  variant="primary"
                  display={["none !important", "flex !important"]}
                >
                  <FormattedMessage {...messages.readMore} />
                </Button>
              )}
            </Box>
          </Flex>
          {content?.difficulty && (
            <Box
              px={1.5}
              height="28px"
              width="100%"
              bg="#FCF9F7"
              fontWeight={600}
              fontSize="13px"
              lineHeight={"16px"}
              borderTop="1px solid grey200"
              alignItems="center"
              whiteSpace={"nowrap"}
              borderBottomLeftRadius="inherit"
              borderBottomRightRadius="inherit"
              overflow="hidden"
              display={["flex", "flex", "none"]}
            >
              <Box height="21px" pt={"2px"} width="28%" textAlign="left">
                {content?.difficulty.text.toUpperCase()}
              </Box>
              <Box height="21px" pt={"2px"} width="20%" textAlign="left">
                {content?.total_time} MIN.
              </Box>
              <Box
                height="21px"
                pt={"2px"}
                overflow="hidden"
                css={{ textOverflow: "ellipsis" }}
              >
                {`${
                  content.ingredients
                    ? content.ingredients.split(",")?.length
                    : content?.recipes_ingredients?.length
                }
                     INGREDIENTEN`}
              </Box>
            </Box>
          )}
        </HoverArea>
      </Box>
    </Link>
  );
};
